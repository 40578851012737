import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoinInfo, CoinInfoJson } from '../models/coin-info-json.model';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

interface CoinInfoResponse {
    data: CoinInfoJson;
    message: string;
    status: number;
}

@Injectable({
    providedIn: 'root'
})
export class CoinInfoService {
    private readonly COIN_INFO_STK: string = 'COIN_INFO_STK';
    private _COIN_INFO_JSON: CoinInfoJson;
    dataFromTransferState: CoinInfoJson;

    private _ixfiCoinInfoSubject = new BehaviorSubject<any>('');
    ixfiCoinInfoObs = this._ixfiCoinInfoSubject.asObservable();
    get coinInfo(): CoinInfoJson {
        return this._COIN_INFO_JSON;
    }

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformID: Object,
        private transferState: TransferState
    ) {
        if (isPlatformBrowser(this.platformID)) {
            const stateTransferKey = makeStateKey<CoinInfoJson>(this.COIN_INFO_STK);
            this._COIN_INFO_JSON = this.transferState.get(stateTransferKey, {});
        }
    }

    /**
     * This function is called in the resolver to fetch essential Coin Data
     * @returns Observable of CoinInfoJson
     */
    fetchCoinInfo(): Observable<CoinInfoJson> {
        let httpObs: Observable<CoinInfoJson>;
        const stateTransferKey = makeStateKey<CoinInfoJson>(this.COIN_INFO_STK);
        if (this.transferState.hasKey(stateTransferKey)) {
            this.dataFromTransferState = this.transferState.get(stateTransferKey, {});
            httpObs = of(this.dataFromTransferState);
        } else {
            httpObs = this.http.get<CoinInfoResponse>(`${environment.IXFI_API}landing-page/coin-info-id`).pipe(
                map(response => this.mapResponse(response)),
                tap(mappedResponse => {
                    if (isPlatformServer(this.platformID)) {
                        this.transferState.set(stateTransferKey, mappedResponse);
                    }
                })
            );
        }
        return httpObs;
        // return this.http
        //     .get<CoinInfoResponse>(`${environment.IXFI_API}landing-page/coin-info-id`)
        //     .pipe(map(response => this.mapResponse(response)));
    }

    private mapResponse = (response: CoinInfoResponse): CoinInfoJson => {
        let responseData: CoinInfoJson = {};
        if (response.status === 200) responseData = response.data;
        this._COIN_INFO_JSON = responseData;
        // Capitalize the coin_code in the COIN_INFO_JSON object
        Object.keys(this._COIN_INFO_JSON).forEach(
            id => (this._COIN_INFO_JSON[id].coin_code = this._COIN_INFO_JSON[id].coin_code.toUpperCase())
        );
        return responseData;
    };

    /**
     * This fnc is used to get the Coin Detail of the Coin ID passed as parameter
     * @param coinId Coin ID
     * @returns Coin Info Detail for the particular Coin ID
     */
    getCoinDetailById(coinId: string): CoinInfo {
        const defaultCoinInfoElement: CoinInfo = {
            _id: '',
            is_active: true,
            coin_code: '',
            coin_name: '',
            display_precision: 0,
            coin_icon: 'shared/ixfi-default-icon.png',
            cs: 0,
            mc: 0,
            trade_default_pair: null,
            swap_default_pair: null,
            convert_default_pair: null
        };
        if (!this._COIN_INFO_JSON) return defaultCoinInfoElement;
        return this._COIN_INFO_JSON[coinId] ?? defaultCoinInfoElement;
    }
}
